import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  value: {},
};

export const DataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    saveData: (state, action: PayloadAction<any>) => {
      const { step, data } = action.payload;
      const existingData = JSON.parse(JSON.stringify(state.value));
      existingData[step] = data;
      state.value = existingData;
    },
    emptyData: (state) => {
      state.value = {};
    },
  },
});

export const { saveData, emptyData } = DataSlice.actions;
export default DataSlice.reducer;
