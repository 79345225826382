import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../components/Loader";

const Home = lazy(() => import("../Home"));
const AllBlogs = lazy(() => import("../AllBlogs"));
const RegisterGrave = lazy(() => import("../RegisterGrave"));
const PrivacyPolicy = lazy(() => import("../PrivacyPolicy"));
const Submitted = lazy(() => import("../Submitted"));
const Map = lazy(() => import("../Map"));

const App = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loader />}>
            <Home />
          </Suspense>
        }
      />

      <Route
        path="/all-blogs"
        element={
          <Suspense fallback={<Loader />}>
            <AllBlogs />
          </Suspense>
        }
      />

      <Route
        path="/register-grave"
        element={
          <Suspense fallback={<Loader />}>
            <RegisterGrave />
          </Suspense>
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <Suspense fallback={<Loader />}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        path="/submitted"
        element={
          <Suspense fallback={<Loader />}>
            <Submitted />
          </Suspense>
        }
      />
      <Route
        path="/map-finder"
        element={
          <Suspense fallback={<Loader />}>
            <Map />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default App;
